<template>
	<div class="page">

		<!-- 搜索条件表单 -->
		<el-form class="ser_form" size="small"  label-width="68px">
            <el-form-item class="el_form_item" label="系统类型">
                    <el-select class="el_input" v-model="system_type" clearable>
                        <el-option label="不限" value=""></el-option>
                        <el-option label="老系统" value="1"></el-option>
                        <el-option label="则一" value="2"></el-option>
                        <el-option label="中物宝" value="3"></el-option>
                    </el-select>
                </el-form-item>
			<el-form-item>
				<el-button type="primary" @click="page_ser">录入</el-button>
				<el-button type="primary" @click="batch_edit">批量修改</el-button>
			</el-form-item>
		</el-form>
		<!-- 表格 -->
		<div class="tab_height">
			<el-table :data="tord" :border="true" :stripe="true" size="small" height="100%" v-loading="list.loading" ref='multipleTable'>
				<el-table-column label="运单编号" >
					<template slot-scope="scope">
						<div class="tab_line_item">{{scope.row.truck_tord_num}}</div>
					</template>
				</el-table-column>
				<el-table-column label="车牌号">
                    <template slot-scope="scope">
						<div class="tab_line_item">{{scope.row.truck_plate_num}}</div>
					</template>
				</el-table-column>
				<el-table-column label="车辆类型（编码）" >
					<template slot-scope="scope">
						<el-input class="el_input" v-model="scope.row.truck_type" placeholder="车辆类型" clearable></el-input>
					</template>
				</el-table-column>
				<el-table-column label="道路运输证号" >
					<template slot-scope="scope">
						<el-input class="el_input" v-model="scope.row.truck_code" placeholder="道路运输证号" clearable></el-input>
					</template>
				</el-table-column>
			</el-table> 
		 </div>
	    <!-- 回到顶部 -->
        <el-backtop target=".page" :bottom="70" :right="120"></el-backtop>
		<!-- 分页 -->
		<!-- <el-pagination 
			class="margin-top-xl" 
			@size-change="page_size_change"
			@current-change="page_current_change" 
			:current-page="page.p" 
			:page-sizes="[10, 50, 100, 200]"
			:page-size="page.num" 
			:total="list.total*1"
			layout="total, sizes,prev, pager, next, jumper" 
		>
		</el-pagination> -->


	</div>
</template>

<script>
import { Loading } from 'element-ui';
	export default {
		components:{
		},
		data() {
			return {

				//表格数据
				list: {
					loading: false,//loading特效
					data: [],//列表数据
					total: 0,//列表总记录数
				},
                system_type:'',//系统类型
				//页码信息
				page: {
					need_num:1,
					num: 10,
					p: 1,
				},
				tord:[],
			}
		},
		created() {

		},
		methods: {
			//批量修改提交
			batch_edit(){
                Loading.service(this.options);
				//修改数据
				this.$my.net.req({
					data:{
						mod:'logink',
						ctr:'set_logink_truck',
						system_type:this.system_type,
                        truck_list:this.tord
					},callback:(data)=>{
						this.$my.other.msg({
							type:'success',
							str:'编辑成功'
					    })
						this.tord = []
                        //关闭加载状态
						let loadingInstance = Loading.service(this.options);
							this.$nextTick(() => { // 以服务的方式调用的 Loading 需要异步关闭
							loadingInstance.close();
						});
					}
				})
			},
			//录入功能
            page_ser(){
              
                if(!this.system_type){
                    this.$my.other.msg({
                        type:'info',
                        str:'请选择系统类型'
                    })
                    
                    return
                }
                this.$my.excle.importExcel((data, dataRef) => {
                    Loading.service(this.options);
					var tels = []
					var truck_list = []
					//获取对应位置下标
					var truck_tord_num_index = data[0].indexOf('运单号')
					var error_info_index = data[0].indexOf('错误明细')!=-1?data[0].indexOf('错误明细'):data[0].indexOf('车辆异常信息')
					console.log(data[0].indexOf('错误明细'))
                    var truck_plate_num_index = data[0].indexOf('车辆牌照号')
					//删除表头
					delete data[0]
					data.forEach((item,index)=> {
						truck_list.push({
							truck_tord_num:item[truck_tord_num_index],
							error_info:item[error_info_index],
							truck_plate_num:item[truck_plate_num_index],
						})
					});
                    this.$my.net.req({
                        take_over_control:1,
                        data:{
                            mod:'logink',
                            ctr:'get_logink_truck_list',
                            system_type:this.system_type,
                            truck_list,
                        },callback:(data)=>{
                            if(data.code !=0){
                                this.$my.other.msg({
                                    type:'info',
                                    str:data.error_info
                                })
                            }
                            // this.list.total =data.truck_list.length
					 		this.tord = data.truck_list
                            //关闭加载状态
							let loadingInstance = Loading.service(this.options);
							this.$nextTick(() => { // 以服务的方式调用的 Loading 需要异步关闭
							loadingInstance.close();
							});
                        }
                    })

                });
            },
			//页宽变化
			page_size_change(num){

				//置入页码
				this.page.num=num

				//读取
				this.get_page_data()
				
			},

			//页码变化
			page_current_change(p){
				
				//置入页码
				this.page.p=p

				//读取
				this.get_page_data()
			},

			//获取货源列表
			get_page_data(){

			},
		}
	}
</script>

<style lang="scss" scoped>
	.page{
		background-color: #fff;
		padding: 20px;
		border-radius: 10px;
		height: 100%;
		overflow: auto;
	}
	.ser_form{
		display: flex;
		align-items: flex-end;
		flex-wrap: wrap;
	}
	.tab_height{
		height: 700px;
	}
	.el_form_item{
		width: 16.6672%;
	}
	.el_input{
		width:94%
	}
	.btn_left{
		margin: 0;
	}
</style>